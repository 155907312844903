$(function() {

    // Options
    $('.switch').rcSwitcher({
        theme: 'light',          // light                    select theme between 'flat, light, dark, modern'
        width: 70,              // 56  in 'px'
        height: 26,             // 22
        blobOffset: 0,          // 2
        reverse: false,          // false                    reverse on off order
        onText: 'YES',          // 'ON'                     text displayed on ON state
        offText: 'NO',          // 'OFF'                    text displayed on OFF state
        inputs: false,           // false                    show corresponding  inputs
        autoFontSize: false,     // false                    auto fit text size with respect to switch height
        autoStick: true         // false                    auto stick switch to its parent side
    });

});
